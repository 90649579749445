.changelog-item-container-header {
  height: 10px;
  padding-bottom: 10px;
}
.changelog-item-remove {
  padding-left: 15px;
  padding-top: 15px;
  color: #877040;
  font-size: 20px;
  cursor: pointer;
  float: left;
}

.changelog-item-date {
  padding-top: 15px;
  padding-right: 15px;
  float: right;
}
.changelog-item-h1 {
  margin-top: 15px;
}
.changelog-item-note {
  font-size: 14px;
}
