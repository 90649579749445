/*
   Rangoon Green: 1A1A1A
   Svart bakgrunn: 22222
   Små detaljer, stygg gull farge: 877040
   Bakgrunnsfarge: DDDDDD
*/
.mainContainer {
  width: 100%;
  height: 100%;
}

.content {
  transition: all 0.3s;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px;
}

.col {
  display: flex;
  flex-direction: col;
  justify-content: space-around;
  padding: 10px;
}

body {
  font-family: "Inconsolata", monospace;
  background: #DDD;
  margin: 0;
  padding: 0;
  font-size: 1.3em;
  display: flex;
  justify-content: center;
}



a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

button {
  background-color: #1A1A1A;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  color: white;
  font-weight: bold;
  outline: 0;
}

button:hover {
  background-color: #36383C;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  opacity: 0;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgba(26, 26, 26, 0.1);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #36383C; 
}

.ItemHover:hover {
  border-right: 1px solid #1A1A1A;
}

.pointer {cursor: pointer;}

/* Do NOT remove this */
.categorySelectorOld {
  background-color: rgba(0,0,0,0.0);
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: none;
  border-bottom: 1px solid black;
  outline: non;
}

/* Do NOT remove this */
.categorySelectorNew {
  background-color: rgba(0,0,0,0.0);
  display: inline-block;
  border: none;
  font-family: "Inconsolata", monospace;
  font-size: 18px;
  border-bottom: 1px solid black;
  outline: non;
}

input {
  border: none;
  border-bottom: 1px solid black;
  font-family: "Inconsolata", monospace;
  font-size: 0.74em;
}

input:hover {
  border-bottom: 1px solid #877040;
}

select {
  border: none;
  border-bottom: 1px solid black;
}

select:hover {
  border-bottom: 1px solid #877040;
}

.WhiteBorder {
  border-bottom: 1px solid grey;
  color: #fff;
}

.bottomPadding {
  padding-bottom: 0.3em;
}

.iconButtonActive {
  filter: drop-shadow(2px 4px 2px rgba(0,0,0,0.2));
}

.iconButtonActive:active {
  transform: scale(0.9);
  filter: drop-shadow(1px 2px 2px rgba(0,0,0,0.2));
}

.dropShadow {
  filter: drop-shadow(2px 4px 2px rgba(0,0,0,0.2));
}

.whiteBackground {
  background-color: white;
}

.borderRadiusEight {
  border-radius: 8px;
}

.tenPixelPadding {
  padding: 10px
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.trayDisplay {
  position: fixed;
  bottom: 0;
  left: 225px;
  width: 100%;
}

.blur {
  background-color: #1A1A1A;
  opacity: 0.8;
  height: 100%;
}

.tableCell {
  margin-left: 10px;
  padding: 10px;
  max-width: 200px;
  min-width: 200px;
  max-height: 54px;
}

.tableCell-button {
  margin: 8px 0 8px 0;
  padding: 0;
  height: 42.4px;
  border: 0px solid black;
  border-top-right-radius: -20%;
}

.indexCell {
  margin-right: 10px;
  padding: 10px;
  max-width: 60px;
  min-width: 60px;
  max-height: 54px;
}

@keyframes extendContainer {
  from { max-width: 0px; }
  to { max-width: 100%; }
}

@keyframes swipeInLeft {
  from { right: -2000px }
  to { right: 0px }
}

@keyframes swipeInRight {
  from { left: -2000px }
  to { left: 0px }
}

@keyframes swipeDown {
  from { top: -2000px }
  to { top: 0px }
}

@keyframes swipeUp {
  from { top: 2000px }
  to { top: 0px }
}

.swipeUpAnimation {
  position: relative;
  top: 0;
  animation: swipeUp 0.3s ease;
}

.swipeDownAnimation {
  position: relative;
  top: 0;
  animation: swipeDown 0.3s ease;
}

.swipeInRightAnimation {
  position: relative;
  left: 0;
  animation: swipeInRight 0.3s ease;
}

.swipeInLeftAnimation {
  position: relative;
  right: 0;
  animation: swipeInLeft 0.3s ease;
}

.extendAnimation {
  max-width: 100%;
  animation: extendContainer 0.3s ease;
}

.extensionContainer {
  display: flex;
  flex-direction: column;
  background-color: #36383C;
  color: white;
  margin: 8px 0 0 0;
  
}

.elementHover {
  background-color: #A6A6A6;
}

.elementHover:hover {
  background-color: #DDD;
}

.tooltip .tooltiptext {
  visibility: hidden;
  position: fixed;
  background-color: #36383C;
  font-size: 16px;
  padding: 7px;
  border-radius: 8px;
  text-align: center;
  color: #ddd;
  bottom: 5px;
  right: -500px;
  z-index: 99;
  transition: right 0.2s ease-in;
}

.tooltip:hover .tooltiptext {
  right: 0;
  visibility: visible;
}

@keyframes slideInFromLeft {
  from {left: -100px}
  to {left: 10px}
}

#fixedPageHeader {
  position: fixed;
  color: #ddd;
  top: 10px;
  left: 10px;
  z-index: 1;
  width: 200px;
  margin: 0;
  animation: slideInFromLeft 0.5s;
}

@keyframes loadingLoop {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(350deg);}
}

@keyframes rotated-loadingLoop {
  0% {transform: rotate(45deg);}
  100% {transform: rotate(145deg);}
}

.loading {
  background-color: #877040;
  width: 15px;
  height: 15px;
  transform: rotate(90deg);
  border: 1px solid #36383C;
  animation: loadingLoop 1s infinite ease-in-out;
  filter: drop-shadow(2px 4px 2px rgba(0,0,0,0.2));
  font-size: 10px;
  margin: 0 10px 0 0;
}

.loading #rotated-loading {
  background-color: #ddd;
  width: 10px;
  height: 10px;
  margin: 7.5px;
  transform: rotate(45deg);
  animation: rotated-loadingLoop 1s infinite ease-in-out;
}

.footer {
  display: flex;
  padding: 10px;
  position: fixed;
  bottom: 0;
  left: 225px;
  width: 100%;
  height: 42px;
  background-color: #1A1A1A;
}





