.ModalFrame {
    display: flex;
    flex-direction: column;
    box-shadow: 3px 6px 9px -1px rgba(0, 0, 0, 0.39);
    background-color: white;
    width: 50vw;
    height: 98vh;
    border: 6px solid #282c34;
    border-radius: 8px;
    position: fixed;
    top: 0;
    left: 25vw;
    z-index: 9;
    overflow-y: auto;
    padding: 5px;
  }

  .TemplateEditorHeader {
    display: flex;
    justify-content: space-between;
  }