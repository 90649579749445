.ProductContainer {
  display: flex;
  justify-content: space-evenly;
  margin-top: 50px;
  margin-left: 50px;
  margin-right: 50px;
}

.ProductSearchContainer {
  max-width: 500px;
  min-width: 500px;
  margin: auto;
}
