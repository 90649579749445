/* ---------------------------------------------------
    DASHBOARD AND CONTENT STYLE
----------------------------------------------------- */

.dashboard-content {
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}


.dashboard-row {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
}

.align-center>* {
  margin-left: auto;
  margin-right: auto;
}

.dashboard-column {
  margin-left: auto;
  margin-right: auto;
}

.todo-item-container {
  border-radius: 8px;
  background: #f3f3f3;
  padding: 10px;
}

.mobile.add-btn {
  display: none;
  font-size: 48px;
  color: #877040;
}

.web.add-btn {
  color: #877040;
  background: #ddd;
  font-size: 30px;
  text-decoration: none;
  border: none;
  cursor: pointer;
  transition: all 0.7s;
}

/*
Mobile
*/
@media only screen and (max-width: 900px) {
  .dashboard-row {
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  }

  .dashboard-content {
    min-width: 100%;
  }

  #todo-items-container {
    width: 100%;
  }

  .todo-item {
    width: auto;
    padding: 10px;
    margin-top: 50px;
  }

  .todo-item-container {
    width: 100%;
  }
}