/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
/*icons sidebar*/
.fa {
  padding-right: 13px;
  color: #877040;
}

.menuButton {
  text-align: left;
}

.sidebar {
  width: 225px;
  padding-top: 50px;
  position: fixed;
  background: url(../../../img/soldier_menu_fade.png) no-repeat;
  background-size: cover;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 999;
  /*background: #1a1a1a;*/
  color: #fff;
  transition: all 0.3s;
}

.sidebar .sidebar-header {
  padding: 18px;
  /*background: #1a1a1a;*/
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

.sidebar ul.components {
  padding: 20px 0;
  border-bottom: 1px solid #fff;
}

.sidebar ul p {
  color: #fff;
  padding: 10px;
}

.sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
}

.sidebar ul li a:hover {
  color: black;
  background: #fff;
}

.menuButton.active {
  color: #333;
  background: #dddddd;
  border-top-left-radius: 10px;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #1a1a1a;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #7386d5;
}

a.article,
a.article:hover {
  background: black !important;
  color: #fff !important;
}

.hamburger-container {
  display: none;
}

/*
Media controls. Create hamburger menu when the screen size is to small
*/

@media only screen and (max-width: 900px) {
  .sidebar {
    margin-left: -250px;
  }

  .onChange.sidebar {
    margin-left: 0;
    box-shadow: 1px 2px 100px #333;
  }
}
