.profile-pictures {
  width: 70px;
  height: 70px;
  border-radius: 10px;
}

@media only screen and (max-width: 900px) {
  .profile-pictures {
    width: 38px;
    height: 38px;
  }
  .profile-div {
    display: flex;
  }
}
