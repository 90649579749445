.loginWrapper {
  height: 100%;
  width: 520px;
  background: #222222;
  margin-top: 200px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  padding: 2em 0;
}

#login-form {
  margin-top: 90px;
}

#loginLogo {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  height: 130px;
  width: 110px;
  left: 0;
  right: 0;
  margin-top: -110px;
}

.form-group {
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
}

.loginFields {
  height: 46px;
  width: 400px;
  margin-top: 30px;
  background: #dddddd;
}

.loginBtn {
  cursor: pointer;
  border-radius: 4px;
  height: 46px;
  width: 100%;
  border: none;
  margin-top: 44px;
  background-color: #877040;
  color: #ffffff;
  font-size: 28px;
}
.forgotPw {
  background: #222222;
  color: #ffffff;
  font-size: 15px;
  width: 100%;
  
}

.forgotPw span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.forgotPw span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.forgotPw:hover span {
  padding-right: 25px;
}

.btn{
  margin: 70px 0.2em;
}

.forgotPw:hover span:after {
  opacity: 1;
  right: 0;
}

.error {
  z-index: 100;
  color: white;
  position: absolute;
  margin-top: 82px;
  left: 39%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 520px) {
  .loginFields {
    margin-left: auto;
    margin-right: auto;
  }
}