.TemplateEditor {
  display: flex;
  flex-direction: column;
  box-shadow: 3px 6px 9px -1px rgba(0, 0, 0, 0.39);
  background-color: white;
  width: 50vw;
  height: 98vh;
  border: 6px solid #282c34;
  border-radius: 8px;
  position: fixed;
  top: 0;
  left: 25vw;
  z-index: 9;
  overflow-y: auto;
}

.ChooseCategoryContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ChooseCategoryContainer label {
  margin-left: 20px;
}

.TemplateEditorHeader {
  display: flex;
  justify-content: space-between;
}

#delete-button {
  margin: auto;
  font-size: 16px;
}

#delete-button:hover {
  color: white;
  background-color: red;
}
