.changelog-container {
  display: none;
  margin-left: auto;
  margin-right: auto;
}

.changelog-item-container {
  border-radius: 25px;
  margin-top: 25px;
  padding: 2px;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.show {
  display: block;
}

.showHideChangelog-btn {
  cursor: pointer;
  transition: all 0.5s;
  border: none;
  border-radius: 4px;
  width: 400px;
  height: 46px;
}