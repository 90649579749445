/* ---------------------------------------------------
    NAVBAR STYLE
----------------------------------------------------- */
.navbar-container {
  height: 50px;
  width: 100%;
  background-color: #1a1a1a;
}

.navbar-brand {
  transform: translateX(-50%);
  left: 50%;
  padding-top: 15px;
  position: absolute;
}

#navBarLogo {
  position: absolute;
  height: 75px;
  width: 65px;
  margin-right: auto;
  margin-left: auto;
  left: 0;
  right: 0;
  margin-top: 15px;
}
.shoppingBasket {
  width: 40px;
  height: 40px;
}

#shoppingCartFooter {
  font-size: 14px;
  border-top: 1px solid #dddddd;
  background-color: #1a1a1a;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
}
.shopBtn {
  width: 100px;
  height: 25px;
  border-radius: 2px;
  border: none;
  color: #dddddd;
  background-color: #1a1a1a;
  padding: 2px;
  margin: 2px;
}
#_checkout {
  float: right;
}
#_deleteAll {
  float: left;
}
#_checkout:after {
  content: "\00bb";
  opacity: 0;
  right: -20px;
  transition: 0.5s;
}

#_checkout:hover:after {
  opacity: 1;
  right: 0;
}
#_deleteAll:after {
  content: "\00bb";
  opacity: 0;
  right: -20px;
  transition: 0.5s;
}

#_deleteAll:hover:after {
  opacity: 1;
  right: 0;
}

@media only screen and (max-width: 900px) {
  .navbar-container {
    background-color: #dddddd;
  }
  #navBarLogo {
    display: none;
  }
  .hamburger-nav {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1031;
  }
  .hamburger-container {
    display: block;
    cursor: pointer;
  }
  .bar1,
  .bar2,
  .bar3 {
    width: 35px;
    height: 5px;
    background-color: #fff;
    margin: 6px 0;
    transition: 0.4s;
    display: block;
  }
  .onChange.bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
  }
  .onChange.bar2 {
    opacity: 0;
  }
  .onChange.bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
  }
}
