.system-btn-template {
  margin-top: 40px;
  cursor: pointer;
  transition: all 0.5s;
  border: none;
  border-radius: 4px;
  width: 400px;
  height: 46px;
}

.system-btn-template-div {
  margin-top: 40px;
  background-color: #1a1a1a;
  cursor: pointer;
  color: white;
  transition: all 0.5s;
  border: none;
  border-radius: 4px;
  width: 400px;
  height: 46px;
}

.align-center {
  margin-left: auto;
  margin-right: auto;
}

.button-row button {
  margin: 1em;
}

.changelog > * {
  margin-left: auto;
  margin-right: auto;
}
