.product-content {
  display: "flex";
}
.product-content-container {
  flex-direction: column;
  justify-content: space-around;
}
.product-form {
  margin-right: auto;
  margin-left: auto;
  width: 400px;
}

.product-form-field {
  display: flex;
  flex-direction: column;
  margin: inherit;
  height: 46px;
  width: 400px;
  margin-top: 30px;
  border-radius: 10px;
}

.btn-product-register {
  width: 100%;
  height: 46px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  margin: 5px;
}
.btn-product-container {
  display: flex;
  flex-direction: row;
}
.select-category-template {
  width: 400px;
  height: 55px;
  margin: 20px;
}
